import { getSportsPromotion } from '@/helpers/composables/useSportsPromotion';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';

// STATE
const state = () => ({
	sportsPromotions: null,
});

export type AppState = ReturnType<typeof state>;

// GETTERS
const getters: GetterTree<AppState, any> = {
	sportsPromotions(state) {
		return state.sportsPromotions;
	},
};

// ACTIONS
const actions: ActionTree<AppState, any> = {
	setSportsPromotions({ commit }) {
		const sportsPromotions = getSportsPromotion();
		commit('SET_SPORTS_PROMOTION', sportsPromotions);
	},
};

// MUTATIONS
const mutations: MutationTree<AppState> = {
	SET_SPORTS_PROMOTION(state, sportsPromotions) {
		state.sportsPromotions = sportsPromotions;
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
} as Module<AppState, any>;

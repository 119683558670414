import { MonetizationType, PresentationType } from '@/@types/graphql-types';
import { useLanguageStore, useSportsStore } from './useStores';
import { onMounted, onServerPrefetch } from 'vue';

interface SportPromotion {
	eventIds: string[];
	package: {
		packageId: number;
		technicalName: string;
		clearName: string;
		iconUrl: string;
	};
	monetizationType: MonetizationType;
	presentationType: PresentationType;
	standardWebURL: string;
	countries: string[];
	startDate: string;
	endDate: string;
	title: string | null;
	subtitle: string | null;
}

const ncaaMarchMadnessEventIds = [
	'se-bkb-47188532',
	'se-bkb-47185652',
	'se-bkb-47179561',
	'se-bkb-47180314',
	'se-bkb-47223737',
	'se-bkb-47223738',
	'se-bkb-47188531',
	'se-bkb-47179288',
];

const nbaLeaguePassPromoEventIds = [
	'se-bkb-43652695',
	'se-bkb-43652697',
	'se-bkb-43652702',
	'se-bkb-43652665',
	'se-bkb-43652705',
	'se-bkb-43652714',
	'se-bkb-43652710',
	'se-bkb-43652718',
	'se-bkb-43652730',
	'se-bkb-43652722',
	'se-bkb-43652736',
	'se-bkb-43652726',
	'se-bkb-43652742',
	'se-bkb-43652737',
	'se-bkb-43652746',
	'se-bkb-43652749',
	'se-bkb-43652753',
	'se-bkb-43652757',
	'se-bkb-43652763',
	'se-bkb-43652766',
	'se-bkb-43652773',
	'se-bkb-43652770',
	'se-bkb-43652778',
	'se-bkb-43652782',
	'se-bkb-43652798',
	'se-bkb-43652785',
	'se-bkb-43652791',
	'se-bkb-43652795',
	'se-bkb-46230552',
	'se-bkb-43652801',
	'se-bkb-43652806',
	'se-bkb-43652814',
	'se-bkb-43652823',
	'se-bkb-43652820',
	'se-bkb-43652826',
	'se-bkb-43652829',
	'se-bkb-43652837',
	'se-bkb-43652835',
	'se-bkb-43652844',
	'se-bkb-43652832',
	'se-bkb-46393464',
	'se-bkb-43652841',
	'se-bkb-43652842',
	'se-bkb-43651989',
	'se-bkb-43652848',
	'se-bkb-43652855',
	'se-bkb-43652851',
	'se-bkb-43652853',
	'se-bkb-43651901',
	'se-bkb-43651917',
	'se-bkb-43651909',
	'se-bkb-46230557',
	'se-bkb-43651945',
	'se-bkb-43651964',
	'se-bkb-43651961',
	'se-bkb-43651932',
	'se-bkb-43651984',
	'se-bkb-43651974',
	'se-bkb-43651951',
	'se-bkb-43652000',
	'se-bkb-43652846',
	'se-bkb-43652008',
	'se-bkb-43652031',
	'se-bkb-43652013',
	'se-bkb-43652038',
	'se-bkb-43652024',
	'se-bkb-43652048',
	'se-bkb-43652059',
	'se-bkb-43652072',
	'se-bkb-43652064',
	'se-bkb-43652079',
	'se-bkb-43652091',
	'se-bkb-43652093',
	'se-bkb-43652098',
	'se-bkb-43652106',
	'se-bkb-43652113',
	'se-bkb-43652118',
	'se-bkb-43652123',
	'se-bkb-43652132',
	'se-bkb-43652130',
	'se-bkb-43652141',
	'se-bkb-43652143',
	'se-bkb-43652149',
	'se-bkb-43652153',
	'se-bkb-43652162',
	'se-bkb-43652160',
	'se-bkb-43652167',
	'se-bkb-43652176',
	'se-bkb-43652177',
	'se-bkb-43652185',
	'se-bkb-43652193',
	'se-bkb-43652187',
	'se-bkb-43652206',
	'se-bkb-43652200',
	'se-bkb-43652210',
	'se-bkb-43652212',
	'se-bkb-43652219',
	'se-bkb-43652223',
	'se-bkb-43652235',
	'se-bkb-43652231',
	'se-bkb-43652240',
	'se-bkb-43652244',
	'se-bkb-43652253',
	'se-bkb-43652248',
	'se-bkb-43652263',
	'se-bkb-43652269',
	'se-bkb-43652273',
	'se-bkb-43652286',
	'se-bkb-43652278',
	'se-bkb-43652291',
	'se-bkb-43652299',
	'se-bkb-43652301',
	'se-bkb-43652307',
	'se-bkb-43652312',
	'se-bkb-43652316',
	'se-bkb-43652326',
	'se-bkb-43652323',
	'se-bkb-43652331',
	'se-bkb-43652342',
	'se-bkb-43652336',
	'se-bkb-43652345',
	'se-bkb-43652354',
	'se-bkb-43652356',
	'se-bkb-43652361',
	'se-bkb-43652371',
	'se-bkb-43652379',
	'se-bkb-43652382',
	'se-bkb-43652366',
	'se-bkb-43652389',
	'se-bkb-43652391',
	'se-bkb-43652396',
	'se-bkb-43652412',
	'se-bkb-43652406',
	'se-bkb-43652402',
	'se-bkb-43652419',
	'se-bkb-43652421',
	'se-bkb-43652432',
	'se-bkb-43652426',
	'se-bkb-43652435',
	'se-bkb-43652442',
	'se-bkb-43652447',
	'se-bkb-43652454',
	'se-bkb-43652459',
	'se-bkb-43652462',
	'se-bkb-43652467',
	'se-bkb-43652471',
	'se-bkb-43652482',
	'se-bkb-43652475',
	'se-bkb-43652486',
	'se-bkb-43652817',
	'se-bkb-43652492',
	'se-bkb-43652501',
	'se-bkb-43652496',
	'se-bkb-43652506',
	'se-bkb-43652507',
	'se-bkb-43652521',
	'se-bkb-43652518',
	'se-bkb-43652527',
	'se-bkb-43652532',
	'se-bkb-43652537',
	'se-bkb-43652541',
	'se-bkb-43652561',
	'se-bkb-43652555',
	'se-bkb-43652552',
	'se-bkb-43652550',
	'se-bkb-43652567',
	'se-bkb-43652570',
	'se-bkb-43652576',
	'se-bkb-43652580',
	'se-bkb-43652584',
	'se-bkb-43652596',
	'se-bkb-43652592',
	'se-bkb-43652589',
	'se-bkb-43652600',
	'se-bkb-43652608',
	'se-bkb-43652614',
	'se-bkb-43652612',
	'se-bkb-43652615',
	'se-bkb-43652622',
	'se-bkb-43652624',
	'se-bkb-43652629',
	'se-bkb-43652633',
	'se-bkb-43652638',
	'se-bkb-43652639',
	'se-bkb-43652653',
	'se-bkb-43652649',
	'se-bkb-43652657',
	'se-bkb-43652647',
	'se-bkb-43652659',
	'se-bkb-43652669',
	'se-bkb-43652672',
	'se-bkb-46393494',
	'se-bkb-43652677',
	'se-bkb-43652683',
	'se-bkb-43652698',
	'se-bkb-43652690',
	'se-bkb-43652693',
	'se-bkb-43652686',
	'se-bkb-43652699',
	'se-bkb-43652704',
	'se-bkb-43652713',
	'se-bkb-43652707',
	'se-bkb-43652717',
	'se-bkb-43652721',
	'se-bkb-43652723',
	'se-bkb-43652731',
	'se-bkb-43652735',
	'se-bkb-43652732',
	'se-bkb-43652745',
	'se-bkb-43652751',
	'se-bkb-43652758',
	'se-bkb-43652752',
	'se-bkb-43652762',
	'se-bkb-43652767',
	'se-bkb-43652774',
	'se-bkb-43652769',
	'se-bkb-43652777',
	'se-bkb-43652741',
	'se-bkb-43652781',
	'se-bkb-43652792',
	'se-bkb-43652784',
	'se-bkb-43652790',
	'se-bkb-43652797',
	'se-bkb-43652800',
	'se-bkb-43652808',
	'se-bkb-43652805',
	'se-bkb-43652813',
	'se-bkb-43652816',
	'se-bkb-43652819',
	'se-bkb-43652821',
	'se-bkb-43652833',
	'se-bkb-43652824',
	'se-bkb-43652827',
	'se-bkb-43652831',
	'se-bkb-43652838',
	'se-bkb-43652843',
	'se-bkb-43652847',
	'se-bkb-43652850',
	'se-bkb-43652849',
	'se-bkb-43652845',
	'se-bkb-43652840',
	'se-bkb-43652858',
	'se-bkb-43652857',
	'se-bkb-43652852',
	'se-bkb-43652854',
	'se-bkb-43652860',
	'se-bkb-43652861',
	'se-bkb-43652859',
	'se-bkb-43652856',
];

const nbaLeaguePromoSubtitles = {
	GB: 'Stream the Playoff Race. All in One Place. New Low Price.',
	FR: 'La course des Playoffs. Au même endroit. À tarif réduit.',
	DE: 'Die NBA Playoffs. Alles an einem Ort. Neuer Tiefpreis.',
	IT: 'La corsa ai playoff. In un unico posto. A prezzo ridotto.',
	ES: 'Los playoffs. Todo está aquí. Con descuentazo.',
	AR: 'Mira la carrera a Playoffs en un solo lugar a mejor precio.',
	MX: 'Mira la carrera a Playoffs en un solo lugar a mejor precio.',
	BR: 'Veja a batalha dos Playoffs em um só lugar a um preço menor.',
	NL: 'Stream de play-offrace. Alles op één plek. Nieuwe lage prijs.',
	PL: 'Streamuj walkę o Playoffs. Wszystko w jednym miejscu. Nowa niska cena.',
	TR: 'Playoff Yarışını Canlı İzleyin. Hepsi Tek Bir Yerde. Yeni İndirimli Fiyatla.',
	PT: 'Siga a corrida dos playoffs. Tudo num só sítio. Novo preço baixo.',
	AT: 'Streame das Playoff-Rennen. Alles an einem Ort. Zum gesenkten Preis.',
};

function getNbaLeaguePassPromoObj(country: string): SportPromotion {
	return {
		eventIds: nbaLeaguePassPromoEventIds,
		package: {
			packageId: 1761,
			technicalName: 'nbaleaguepass',
			clearName: 'NBA League Pass',
			iconUrl: '/icon/319882438/s100/nbaleaguepass.{format}',
		},
		monetizationType: MonetizationType.Flatrate,
		presentationType: PresentationType.Hd,
		standardWebURL: 'https://prf.hn/click/camref:1100lpCRf/destination:DEEPLINK',
		countries: ['GB', 'FR', 'DE', 'IT', 'ES', 'AR', 'MX', 'BR', 'NL', 'PL', 'TR', 'PT', 'AT'],
		startDate: '2025-03-14',
		endDate: '2025-04-13',
		title: null,
		subtitle: nbaLeaguePromoSubtitles[country as keyof typeof nbaLeaguePromoSubtitles],
	} as SportPromotion;
}

function sportsPromotions(country: string): SportPromotion[] {
	return [
		getNbaLeaguePassPromoObj(country),
		{
			eventIds: ncaaMarchMadnessEventIds,
			package: {
				packageId: 15,
				technicalName: 'hulu',
				clearName: 'Hulu',
				iconUrl: '/icon/116305230/s100/hulu.{format}',
			},
			monetizationType: MonetizationType.Flatrate,
			presentationType: PresentationType.Hd,
			standardWebURL: 'https://www.hulu.com/ncaa-march-madness',
			countries: ['US'],
			startDate: '2025-03-28',
			endDate: '2025-03-31',
			title: null,
			subtitle: 'Watch NCAA March Madness Basketball Live on Hulu',
		},
		{
			eventIds: ['se-bkb-47259152'],
			package: {
				packageId: 582,
				technicalName: 'amazonparamountplus',
				clearName: 'Paramount+ Amazon Channel',
				iconUrl: '/icon/246478651/s100/amazonparamountplus.{format}',
			},
			monetizationType: MonetizationType.Flatrate,
			presentationType: PresentationType.Hd,
			standardWebURL:
				'https://www.amazon.com/gp/video/detail/B0DXN782KV?tag=justussports-20&token=ADE07EB7B9E7DB86DEEEF0D5D4FC5F0B5FB44D3F',
			countries: ['US'],
			startDate: '2025-04-04',
			endDate: '2025-04-05',
			title: null,
			subtitle: 'Watch NCAA March Madness Basketball Live',
		},
		{
			eventIds: ['se-bkb-47258104'],
			package: {
				packageId: 582,
				technicalName: 'amazonparamountplus',
				clearName: 'Paramount+ Amazon Channel',
				iconUrl: '/icon/246478651/s100/amazonparamountplus.{format}',
			},
			monetizationType: MonetizationType.Flatrate,
			presentationType: PresentationType.Hd,
			standardWebURL:
				'https://www.amazon.com/gp/video/detail/B0DDF9J371?tag=justussports-20&token=ADE07EB7B9E7DB86DEEEF0D5D4FC5F0B5FB44D3F',
			countries: ['US'],
			startDate: '2025-04-04',
			endDate: '2025-04-05',
			title: null,
			subtitle: 'Watch NCAA March Madness Basketball Live',
		},
		{
			eventIds: ['se-bkb-47319513'],
			package: {
				packageId: 582,
				technicalName: 'amazonparamountplus',
				clearName: 'Paramount+ Amazon Channel',
				iconUrl: '/icon/246478651/s100/amazonparamountplus.{format}',
			},
			monetizationType: MonetizationType.Flatrate,
			presentationType: PresentationType.Hd,
			standardWebURL:
				'https://www.amazon.com/gp/video/detail/B0DYF1KXTZ?tag=justussports-20&token=ADE07EB7B9E7DB86DEEEF0D5D4FC5F0B5FB44D3F',
			countries: ['US'],
			startDate: '2025-04-07',
			endDate: '2025-04-08',
			title: null,
			subtitle: 'Watch NCAA March Madness Basketball Live',
		},
	];
}

export function getSportsPromotion() {
	const { country } = useLanguageStore();

	// Priority is determined by position in the array. The first item has the highest priority.
	const promotions = sportsPromotions(country.value).filter(promotion => {
		const currentDate = new Date();
		return (
			promotion.countries.includes(country.value) &&
			Number(currentDate) > new Date(promotion.startDate).setUTCHours(0, 0, 0, 0) &&
			Number(currentDate) < new Date(promotion.endDate).setUTCHours(23, 59, 59, 999)
			// && promotion.eventIds.includes(eventId)
		);
	});
	return { promotions };
}

export function useSportsPromotion() {
	const { setSportsPromotions, sportsPromotions } = useSportsStore();

	onServerPrefetch(async () => await setSportsPromotions());

	onMounted(async () => await setSportsPromotions());

	return { sportsPromotions };
}
